interface PrismicImage {
  url: string
  dimensions: {
    width: number
    height: number
  }
}

export function imageSrcSet(
  prismicImage: PrismicImage,
  widths: number[] = [],
  imgCdn: string | null = null
) {
  const srcset = []

  if (imgCdn) {
    if (widths.length > 0) {
      for (const w of widths) {
        srcset.push(
          `${fromPrismicImageConfToBunnyImageLink(
            imgCdn,
            prismicImage,
            1,
            w
          )} ${w}w`
        )
      }
    } else {
      srcset.push(
        `${fromPrismicImageConfToBunnyImageLink(imgCdn, prismicImage)} 1x`
      )

      if (prismicImage.dimensions.width > 2000) {
        srcset.push(
          `${fromPrismicImageConfToBunnyImageLink(
            imgCdn,
            prismicImage,
            1.5
          )} 1.5x`
        )
      } else {
        srcset.push(
          `${fromPrismicImageConfToBunnyImageLink(imgCdn, prismicImage, 2)} 2x`
        )
      }
    }
  } else {
    const img = new URL(prismicImage.url)
    img.searchParams.set('cs', 'srgb')

    const h = parseInt(img.searchParams.get('h') ?? '0')

    if (widths.length > 0) {
      for (const w of widths) {
        img.searchParams.set('w', `${w}`)

        if (img.searchParams.has('rect')) {
          img.searchParams.delete('h')
        } else if (img.searchParams.has('h')) {
          img.searchParams.set(
            'h',
            `${Math.round((h * w) / prismicImage.dimensions.width)}`
          )
        }

        srcset.push(`${img.toString()} ${w}w`)
      }
    } else {
      srcset.push(`${img.toString()} 1x`)

      if (prismicImage.dimensions.width > 2000) {
        img.searchParams.set('dpr', '1.5')
        srcset.push(`${img.toString()} 1.5x`)
      } else {
        img.searchParams.set('dpr', '2')
        srcset.push(`${img.toString()} 2x`)
      }
    }
  }

  return srcset.join(', ')
}

export function fromPrismicImageConfToBunnyImageLink(
  bunnyUrl: string,
  prismicImage: PrismicImage,
  dpr: number = 1,
  forceWidth?: number
): string {
  const { url } = prismicImage
  const width = prismicImage.dimensions.width
  const height = prismicImage.dimensions.height

  const img = new URL(url)
  const bunny = new URL(bunnyUrl)

  if (forceWidth) {
    bunny.searchParams.set('width', forceWidth.toString())

    if (!img.searchParams.has('rect')) {
      bunny.searchParams.set(
        'height',
        Math.round((height * forceWidth) / width).toString()
      )
    }

    if (forceWidth > 1400) {
      bunny.searchParams.set('quality', '65')
    } else {
      bunny.searchParams.set('quality', '70')
    }
  } else {
    bunny.searchParams.set('width', (width * dpr).toString())
    bunny.searchParams.set('height', (height * dpr).toString())

    if (width > 1400) {
      bunny.searchParams.set('quality', '65')
    } else {
      bunny.searchParams.set('quality', '70')
    }
  }

  if (img.searchParams.has('rect')) {
    const rect = img.searchParams.get('rect')!
    const [x, y, w, h] = rect.split(',')

    bunny.searchParams.set('crop', `${w},${h},${x},${y}`)
  }

  return bunny.toString()
}
