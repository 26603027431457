<template>
  <div
    class="choose-context"
    :class="ready ? 'ready' : ''"
    data-cy-choose-context
  >
    <icon-logo class="d-none d-md-block logo-bg" />
    <div class="choose-context__logo mb-0">
      <h1 class="mb-0">
        <icon-logo />
        <span class="visually-hidden">Asphalte</span>
      </h1>
    </div>
    <nuxt-link
      :to="localePath('/h')"
      data-link="h"
      data-cy="homme"
      prefetch
      @mouseover.native="hoverOn('h')"
    >
      <span
        class="h4"
        :class="`text-color-${color(prismicDocument.data.homme_text_color)}`"
        >{{ $t('context.h') }}</span
      >

      <image-simple
        v-if="img.h"
        :image="img.h"
        :image-cdn="img.hCdn"
        alt=""
        class="d-md-none"
        sizes="100vw"
        :widths="[400, 800, 1600]"
      />
      <image-simple
        v-if="img.h"
        :image="img.h"
        :image-cdn="img.hCdn"
        alt=""
        class="d-none d-md-block"
        sizes="50vw"
        :widths="[800, 1600, 2400]"
      />
    </nuxt-link>
    <nuxt-link
      :to="localePath('/f')"
      data-link="f"
      data-cy="femme"
      prefetch
      @mouseover.native="hoverOn('f')"
    >
      <span
        class="h4"
        :class="`text-color-${color(prismicDocument.data.femme_text_color)}`"
        >{{ $t('context.f') }}</span
      >
      <image-simple
        v-if="img.f"
        :image="img.f"
        :image-cdn="img.fCdn"
        alt=""
        class="d-md-none"
        sizes="100vw"
        :widths="[400, 800, 1600]"
      />
      <image-simple
        v-if="img.f"
        :image="img.f"
        :image-cdn="img.fCdn"
        alt=""
        class="d-none d-md-block"
        sizes="50vw"
        :widths="[800, 1600, 2400]"
      />
    </nuxt-link>
  </div>
</template>

<script>
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'

import IconLogo from '@/components/icons/Logo'
import ImageSimple from '@/components/Image/ImageSimple'

import services from '@/services'

export default {
  name: 'Home',
  components: { IconLogo, ImageSimple },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  layout: 'home',
  transition() {
    return ''
  },
  async asyncData({ $prismic, $logError, i18n, error }) {
    try {
      const prismicDocument = await $prismic.client.getSingle('accueil', {
        lang: i18n.locale,
      })

      return {
        prismicDocument,
      }
    } catch (e) {
      $logError.captureException(e)

      error({
        statusCode: 404,
        message: `Accueil not found for ${i18n.locale}`,
      })
    }
  },
  data: () => ({
    ready: false,
    prismicDocument: null,
    zoom: 50,
  }),
  head() {
    if (this.prismicDocument) {
      let title = this.$t('seo.defaultTitle')

      if (this.prismicDocument.data.meta_title) {
        title = this.prismicDocument.data.meta_title
      }

      const route = `${
        process.env.BASE_URL ? process.env.BASE_URL : 'http://localhost:3000'
      }${this.$nuxt.$route.path}`

      const head = services.pageService.getHead(
        {
          type: 'home',
          title,
          route,
          prismic: this.prismicDocument,
          defaultTitle: this.$t('seo.defaultTitle'),
          defaultDesc: this.$t('seo.defaultDesc'),
        },
        this.$generateAlternateLinksMeta(
          this.prismicDocument.alternate_languages
        )
      )

      head.bodyAttrs = {
        class: 'context',
      }

      return head
    }

    return {
      bodyAttrs: {
        class: 'context',
      },
    }
  },
  computed: {
    img() {
      return {
        h:
          this.prismicDocument.data.homme && this.prismicDocument.data.homme.url
            ? this.prismicDocument.data.homme
            : null,
        hCdn: this.prismicDocument.data.homme_cdn,
        f:
          this.prismicDocument.data.femme && this.prismicDocument.data.femme.url
            ? this.prismicDocument.data.femme
            : null,
        fCdn: this.prismicDocument.data.femme_cdn,
      }
    },
  },
  async created() {
    if (process.client) {
      await this.$nextTick()
      this.ready = true
    }
  },
  mounted() {
    this.$track.page(this.$metaInfo, this.$route, this.$nuxt.context.from)
    this.ready = true

    if (
      process.env.NODE_ENV === 'production' &&
      process.env.BUILD_ENV === 'production'
    ) {
      this.$axios
        .get(`${process.env.BASE_URL}/.netlify/functions/getBuildId`)
        .then((getBuildIdFromFunction) => {
          if (getBuildIdFromFunction?.data?.buildId) {
            localStorage.setItem(
              'netlifyBuildID',
              getBuildIdFromFunction.data.buildId
            )
          }
        })
        .catch((err) => {
          this.$logError.captureException(err)
        })
    }
  },
  methods: {
    color(color) {
      switch (color) {
        case 'secondaire-2':
          return 'secondary-2'
        case 'tertiaire':
          return 'tertiary'
        case 'jaune':
          return 'yellow'
        case 'soleil':
          return 'sun'
        default:
          return 'secondary'
      }
    },
    imgLoaded(context) {
      this.loaded[context] = true
    },
    hoverOn(context) {
      document.body.classList.remove('to-f', 'to-h')
      document.body.classList.add(`to-${context}`)
    },
  },
}
</script>

<style lang="scss">
.choose-context {
  height: 100vh;
  position: relative;
  background-color: var(--tertiary-color);
  overflow: hidden;

  .logo-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    height: rem(100px);
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    pointer-events: none;
    transition: all 0.8s;

    @include mq($from: tablet) {
      top: rem($spacing * 3);
      transform: translateX(-50%);
    }

    .logo {
      display: block;
      width: 85vw;
      max-width: rem(250px);
      color: var(--tertiary-color);
      transition: all 0.8s;

      @include mq($from: tablet) {
        width: auto;
        max-width: none;
        height: rem(35px);
      }
    }
  }

  &__loading {
    position: absolute;
    top: calc(100% + var(--spacing));
    left: 0;
    right: 0;

    @include mq($from: tablet) {
      top: calc(100% + var(--spacing) * 2);
    }
  }

  a {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    z-index: 1;
    overflow: hidden;

    span {
      position: relative;
      z-index: 3;
      pointer-events: none;

      &::after {
        content: '';
        position: absolute;
        background: currentcolor;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: rem(2px);
        transition: all 0.8s;
        border-radius: 2px;
      }
    }

    .image--simple {
      position: static;

      > img {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        transition: all 0.3s;
      }
    }

    @include mq($until: tablet) {
      left: 0;
      right: 0;
      height: 50%;
      letter-spacing: 0.1em;

      &:first-child {
        top: 0;
      }

      &:last-child {
        bottom: 0;
      }
    }

    @include mq($from: tablet) {
      top: 0;
      bottom: 0;
      width: 50%;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }

    @include on-hover-and-focus {
      letter-spacing: 0.16em;
      z-index: 2;

      span {
        &::after {
          width: 100%;
          height: rem(1px);
          border-radius: 0;
        }
      }
    }
  }

  &:not(.ready) {
    @include mq($until: tablet) {
      .logo {
        color: var(--secondary-color);
      }
    }

    a {
      opacity: 0;
    }

    [data-link='h'] {
      transform: translateY(-5%);

      @include mq($from: tablet) {
        transform: translateX(-5%);
      }
    }

    [data-link='f'] {
      transform: translateY(5%);

      @include mq($from: tablet) {
        transform: translateX(5%);
      }
    }
  }
}
</style>
